@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Public+Sans:400,500,600,700,800,900&display=swap');

@font-face {
  font-family: 'VT323';
  src: url('../fonts/VT323-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src:
      url('//s3.amazonaws.com/bright.public/fonts/segoe/SEGOEUI.otf') format('otf'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/SEGOEUI.woff') format('woff'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/SEGOEUI.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Light';
  src:
      url('//s3.amazonaws.com/bright.public/fonts/segoe/Segoe%20UI%20Light.otf') format('otf'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/Segoe%20UI%20Light.woff') format('woff'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/Segoe%20UI%20Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Bold';
  src:
      url('//s3.amazonaws.com/bright.public/fonts/segoe/segoe-ui-bold.otf') format('otf'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/segoe-ui-bold.woff') format('woff'),
      url('//s3.amazonaws.com/bright.public/fonts/segoe/segoe-ui-bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans';
  src: url('../fonts/Source_Sans_3/SourceSans3-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ThinItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald/Oswald-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf');
  font-optical-sizing: auto;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/Nunito_Sans/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf');
  font-optical-sizing: auto;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf');
  font-optical-sizing: auto;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo/Archivo-VariableFont_wdth,wght.ttf');
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo/Archivo-Italic-VariableFont_wdth,wght.ttf');
  font-optical-sizing: auto;
  font-style: italic;
}
