html,
body,
#root {
  height: 100%;
  margin: 0;
}

.h-full {
  height: 100%;
}

p {
  margin: 0
}

.quill-editor-image-embed {
  max-width: 100%;
}

.ql-size-small {
  font-size: .75rem;
}

.ql-size-large {
  font-size: 1.5rem;
}

.ql-size-huge {
  font-size: 2rem;
}

.ql-tooltip {
  z-index: 1;
}
